import { createTheme } from "@material-ui/core";
import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

const light = {
  black,
  white,
  primary: {
    contrastText: white,
    main: colors.lightBlue[700],
  },
  secondary: {
    contrastText: white,
    main: colors.blue[500],
  },
  sidebar: {
    main: colors.grey[200],
  },
  background: {
    default: white,
    paper: colors.grey[200],
  },
  text: {
    primary: black,
    secondary: colors.grey[800],
  },
};
const theme = createTheme({
  palette: light,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
