import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import { Dashboard, Info, List, CardGiftcard, People, QuestionAnswer } from "@material-ui/icons";
import { SidebarNav, Profile } from "./components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { api } from "api";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 250,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: "#F4F6F8",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const mainApps = [
    {
      external: false,
      title: "Home",
      href: "/",
      icon: <List />,
    },
    {
      external: false,
      title: "Search",
      href: "/search",
      icon: <Dashboard />,
    },
    {
      external: false,
      title: "About",
      href: "/help",
      icon: <Info />,
    },
    {
      external: false,
      title: "FAQ",
      href: "/faq",
      icon: <QuestionAnswer />,
    },
    {
      external: false,
      title: "People",
      href: "/people",
      icon: <People />,
    },
    {
      external: true,
      title: "Donate",
      href: "https://www.rescue-lab.org/donate",
      icon: <CardGiftcard />,
    },
  ];

  const [apps, setApps] = useState([]);

  useEffect(() => {
    axios
      .get(api.appList)
      .then((res) => {
        let newarr = res.data.apps.filter((a) => !a.name.includes("#"));
        let newarr2 = newarr.filter((a) => !a.name.includes("+"));
        setApps(newarr2);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <div style={{ display: "flex", alignItems: "center", padding: "5px" }}>
          <Profile />
          <Autocomplete
            id="combo-box-demo"
            options={apps}
            getOptionLabel={(option) => `${option.name} - ${option.source}`}
            style={{ width: 200 }}
            onChange={(event, value) => {
              if (value) {
                props.history.push({
                  pathname: "/datapanel/" + value.name + "/" + value.source,
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search apps..." variant="outlined" />
            )}
          />
        </div>

        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={mainApps} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default withRouter(Sidebar);
