import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const TopbarNoMenu = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link href="/" color="inherit">
            The App Danger Project <sup>Beta</sup>
          </Link>
        </Typography>
        {/* <h1 style={{ color: "white" }}>app</h1> */}
        <div className={classes.flexGrow}></div>
        <Hidden mdDown>
          <RouterLink to="/" style={{ color: "white" }}></RouterLink>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopbarNoMenu.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default TopbarNoMenu;
