// for local development, baseurl = "http://localhost:8000"
// for staging, baseurl = "http://ec2-54-85-82-161.compute-1.amazonaws.com:8000"
const baseurl = "https://api.app-danger.org";

const api = {
  appList: baseurl + "/applist/",
  appAllData: baseurl + "/appdata/",
  convertTitle: baseurl + "/convert_title/",
  appReviews: baseurl + "/appreviews/",
  randomReview: baseurl + "/randomreview/",
  rankings: baseurl + "/rankings/",
};

export default api;
export { baseurl };
