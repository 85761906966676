import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RoutePublic } from "./components";
// import { Main as MainLayout } from "./layouts";
import { MainWithSidebar } from "./layouts";

import { Home, DataPanel, Help, Ranking, People, FAQ } from "./views";

const Routes = () => {
  return (
    <Switch>
      <RoutePublic
        component={Ranking}
        exact
        layout={MainWithSidebar}
        path="/"
      />
      <RoutePublic
        component={DataPanel}
        exact
        layout={MainWithSidebar}
        path="/datapanel/:AppName/:AppSource"
      />
      <RoutePublic
        component={Help}
        exact
        layout={MainWithSidebar}
        path="/help"
      />
      <Redirect exact from="/datapanel" to="/" />
      <RoutePublic
        component={Home}
        exact
        layout={MainWithSidebar}
        path="/search"
      />
      <RoutePublic
        component={People}
        exact
        layout={MainWithSidebar}
        path="/people"
      />
      <RoutePublic
        component={FAQ}
        exact
        layout={MainWithSidebar}
        path="/faq"
      />
    </Switch>
  );
};

export default Routes;
