import { Box, Button, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useState } from "react"

export function DontationHyperlink() {
    return (<a href="https://www.rescue-lab.org/donate" target="_blank" rel="noreferrer">
    donation page
  </a>)
}

export function ErrorReportingHyperlink() {
    return (<a href="https://forms.gle/unLfLuFmjCPVVm8f8" target="_blank" rel="noreferrer">
              this error form
            </a>)
}

export function NewAppAnalysisHyperlink() {
    return (<a href="https://forms.gle/eQuDFGUXqYdfh2GB6" target="_blank" rel="noreferrer">
              this suggestion form
            </a>)
}

export function NcmecReportingHyperlink() {
    return (<a href="https://report.cybertip.org" target="_blank" rel="noreferrer">
    National Center for Missing and Exploited Children
  </a>)
}

export function AppDangerPaperHyperlink() {
    return (<a href="http://www.cs.umass.edu/~brian/levine.kops22.pdf" target="_blank" rel="noreferrer">Indications of Child Sexual Abuse Revealed in App-Store Reviews</a>)
}

export function BrianBioHyperlink() {
    return (<a href="https://www.cs.umass.edu/~brian" target="_blank" rel="noreferrer">Brian Levine</a>)
}

export function HaniBioHyperlink() {
    return (<a href="https://farid.berkeley.edu" target="_blank" rel="noreferrer">Hany Farid</a>)
}

export function EdBioHyperlink() {
    return (<a href="https://rigr.ai" target="_blank" rel="noreferrer">Ed Dixon</a>)
}

export function Soups2022Hyperlink() {
   return (<a href="https://cuts.soic.indiana.edu/KOPS2022.html#Agenda" target="_blank" rel="noreferrer">
   Usenix SOUPS 2022: Workshop on Kids Online Privacy and Safety</a>) 
}

export function SahilBioHyperlink() {
    return (<a href="https://www.linkedin.com/in/sahilx13" target="_blank" rel="noreferrer">Sahil Sharma</a>)
}

export function VirginiaBioHyperlink() {
    return (<a href="https://www.cics.umass.edu/people/partridge-virginia" target="_blank" rel="noreferrer">Virginia Patridge</a>)
}

export function OakFoundationHyperlink() {
    return (<a href="https://oakfnd.org/programmes/prevent-child-sexual-abuse" target="_blank" rel="noreferrer">Oak
            Foundation</a>)
}

export function ManyResourceHyperlink() {
    return (<a href="https://www.protectchildren.ca/en/resources-research/trending-resources-timely-support/" target="_blank" rel="noreferrer">many
    resources</a>)
}

export function ParentResourcesHyperlink() {
    return (
        <ol style={{ listStyle: 'none' }}>
        <li><a href="https://www.icactaskforce.org/internetsafety" target="_blank" rel="noreferrer">https://www.icactaskforce.org/internetsafety</a></li>
        <li><a href="https://www.justice.gov/coronavirus/keeping-children-safe-online" target="_blank" rel="noreferrer">https://www.justice.gov/coronavirus/keeping-children-safe-online</a></li>
        <li><a href="https://www.protectchildren.ca/en/resources-research/trending-resources-timely-support/" target="_blank" rel="noreferrer">https://www.protectchildren.ca/en/resources-research/trending-resources-timely-support/</a></li>
        </ol>
    )
}

export function SearchAllApps(props) {
    const [selectedApp, setSelectedApp] = useState("");
    return (
        <div>
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography variant="h4" gutterBottom component="div">
                Search for an app to find reviews indicating child sexual abuse
              </Typography>
            </Box>
            <Box sx={{ my: 3, mx: 2, maxWidth: 800 }}>
              <Autocomplete
                id="combo-box-demo"
                options={props.apps}
                getOptionLabel={(option) => `${option.name} - ${option.source}`}
                onChange={(event, value) => {
                  setSelectedApp(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Start typing an app name..."
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box sx={{ my: 3, mx: 2, maxWidth: 800 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  if (selectedApp) {
                    props.history.push({
                      pathname:
                        "/datapanel/" + selectedApp.name + "/" + selectedApp.source,
                    });
                  }
                }}
                id="go-button"
              >
                Search
              </Button>
            </Box>
        </div>
    )
}