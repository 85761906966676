import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import screen1 from "../../images/screen1.PNG";
import screen2 from "../../images/screen2.PNG";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Help = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box sx={{ width: "100%", justifyContent: "center" }}>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid container style={{ justifyContent: "center" }}>
            <Typography gutterBottom variant="h4" component="div">
              About the App Danger project
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography color="text.secondary" variant="body1">
            <strong>“Is this app safe for my child?”</strong> is a question asked by parents around the world that too often goes unanswered. One source of information is the set of user reviews posted to Apple’s and Google’s app stores.
             For some apps, the reviews include complaints of sexual exploitation of minors. A single example follows for the Hoop app, which is rated for children 12 years and older.
          </Typography>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography
            style={{ marginLeft: 30 }}
            color="text.secondary"
            variant="body1"
          >
            <strong>Hoop 2020-09-21</strong> (1 star){" "}
            <i>
              This app has a serious child porn problem — I got through this app
              and every 10 posts is and advertisement for child pornography. I
              have reported like 30 of these accounts and they still keep
              coming. Real messed up
            </i>
          </Typography>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography color="text.secondary" variant="body1">
          For many parents, even reviews like this might be sufficient for avoiding the app completely. 
           At the very least, it should pique an interest in seeing all reviews that suggest the app is dangerous.
          </Typography>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography color="text.secondary" variant="body1">
          Unfortunately, it can be challenging for parents to discover reviews that indicate that minors may face dangers related to sexual abuse or exploitation. As an example, shown below is the information page for Hoop on the Apple App Store. 
          Hoop has many written reviews, and the default view presents reviews that are the "Most Helpful". They can also be sorted by those that are "Most Critical" to reveal 1-star reviews. However, as shown in this example, 1-star reviews are often focused on other aspects of the app that users find unfavorable and may not necessarily highlight reports of dangers to children.
          </Typography>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid>
              <img
                src={screen1}
                alt="hoop app screen"
                width="180"
                height="400"
              />
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid>
              <img src={screen2} alt="hoop reviews" width="180" height="400" />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography color="text.secondary" variant="body1">
            To increase the visibility of reports of apps being used to lure
            or sexually exploit children in app reviews, we searched for such
            reviews and collected them in one place, for a relatively small set
            of apps. We focused on apps that are social in nature, and due to
            this modest effort, we were able to identify many apps that had a
            concerning number reports of child sexual exploitation. Due to
            resource constraints, we could not examine all apps on the two app
            stores, though we hope to do so in the future.
          </Typography>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography color="text.secondary" variant="body1">
            For more information, read our 
            <span>&nbsp;</span>
            <Link href={"/faq/"} color="primary">
              FAQ
            </Link>. 
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Help;
