import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { api } from "api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { SearchAllApps } from "components/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Home = (props) => {
  const [apps, setApps] = useState([]);
  const [randomReview, setRandomReview] = useState({});

  const getRandom = () => {
    axios
      .get(api.randomReview)
      .then((res) => {
        setRandomReview(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get(api.appList)
      .then((res) => {
        let newarr = res.data.apps.filter((a) => !a.name.includes("#"));
        let newarr2 = newarr.filter((a) => !a.name.includes("+"));
        setApps(newarr2);
      })
      .catch((err) => {
        console.log(err);
      });
    getRandom();
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box sx={{ width: "100%", justifyContent: "center" }}>
        <SearchAllApps apps={apps} history={props.history}/>
        {randomReview === {} ? null : (
          <Box sx={{ my: 3, mx: 2, maxWidth: 800 }}>
            <Card className={classes.root}>
              <CardContent>
                <div align="left">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography>
                      <strong>Random Review</strong>
                    </Typography>
                    <hr />
                    <hr />
                    <hr />
                    <IconButton onClick={getRandom}>
                      <RefreshIcon />
                    </IconButton>
                  </div>
                </div>
                <br />
                <div align="left">
                  <Typography>{randomReview.review_content}</Typography>
                </div>
                <br />
                <div align="left">
                  <Button
                    type="submit"
                    fullWidth
                    variant="text"
                    color="primary"
                    onClick={() => {
                      props.history.push({
                        pathname:
                          "/datapanel/" +
                          randomReview.title +
                          "/" +
                          randomReview.source,
                      });
                    }}
                    id="go-button"
                  >
                    <Typography>{`${randomReview.title}`}</Typography>
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Home;
