import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { BrianBioHyperlink, EdBioHyperlink, HaniBioHyperlink, OakFoundationHyperlink, SahilBioHyperlink, VirginiaBioHyperlink } from "components/helper";
import umass_logo from "../../images/umass_logo.png";
import berkeley_logo from "../../images/berkeley_logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const People = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box sx={{ width: "100%", justifyContent: "center" }}>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid container style={{ justifyContent: "center" }}>
            <Typography gutterBottom variant="h4" component="div">
            People behind the project
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography color="text.secondary" variant="body1">
          A number of people have worked to put together this website and the data that appears on it:
            <ul style={{ marginLeft: 30 }}>
          <li><BrianBioHyperlink/>, <SahilBioHyperlink/>, Jagath Jai Kumar (UMass Amherst).</li>
          <li><HaniBioHyperlink/> (Univ. of California Berkeley).</li>
          <li><EdBioHyperlink/> and Eloghosa Ikponmwoba (Rigr AI).</li>
          <li><VirginiaBioHyperlink/> (UMass Amherst) built the RoBERTa-based classifer that we use to process reviews.</li>
          <li>Jeng-Yu Chou (UMass Amherst) and Shruti Chanumolu (UMass Amherst) helped build earlier versions of the project web site. </li>
          <li> A number of students have worked to manually label ground truth data. 
           <ul>
           <li style={{marginLeft: 30}}>At UMass Amherst: Elyse Dotsie, Alyssa Lanter, Lila Masand, Itir Sayar, Spurthi Tallam, Manan Talwar, and Jerry Zeng.</li>
           <li style={{marginLeft: 30}}>At Rigr AI: Peace Ekeinde, Authority Michael Odili, and Onyiriuka Ngozi Palmer.</li>
           </ul>
          </li>
            </ul>
          </Typography>
        </Box>

        <Box sx={{ my: 3, mx: 2 }}>
          <Typography color="text.secondary" variant="body1">
            Work by UMass Amherst and UC Berkeley was funded in part by {<OakFoundationHyperlink/>}.
          </Typography>
        </Box>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid>
              <img
                src={umass_logo}
                alt="UMass Logo"
                height="100"
              />
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid>
              <img src={berkeley_logo} alt="UC Berkeley Logo" height="100" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default People;
