import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { api } from "api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MUIDataTable from "mui-datatables";
import AssignmentIcon from "@material-ui/icons/Assignment";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@mui/material/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { ErrorReportingHyperlink, ManyResourceHyperlink } from "components/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));
function capitalizeFirstLetter(string) {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch {
    return "";
  }
}

const TimeConverter = (props) => {
  return (
    <p>
      {new Date(props.time.replace(/ /g, "T")).toLocaleString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
    </p>
  );
};

const InfoPanel = (props) => {
  const classes = useStyles();
  var percentile = Math.round(100 * props?.data?.percentile);

  if (Math.round(100 * props?.data?.percentile) === 0) {
    percentile = 0;
  } else {
    percentile = Math.round(100 * props?.data?.percentile);
  }


  const formatNReviews = (n) => {
    if (isNaN(n)) {
      return "loading...";
    }

    if (n < 100) {
      return n;
    }
    return `${Math.floor(n / 100) * 100}+`;
  };

  const getGrade = (percentile, abuse_review_count) => {
    if (abuse_review_count === 0 && !isNaN(props?.data?.review_count)) {
      return ["", "green"];
    } else if (
      abuse_review_count > 0 &&
      percentile <= 50 &&
      !isNaN(props?.data?.review_count)
    ) {
      return ["We found a number of reviews that indicate that this app is unsafe for children.", "orange"];
    } else if (
      abuse_review_count > 0 &&
      percentile > 50 &&
      !isNaN(props?.data?.review_count)
    ) {
      return ["We found a significant number of reviews that indicate that this app is unsafe for children.", "red"];
    } else {
      return ["loading...", "grey"];
    }
  };


  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          App Information
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          <strong>{"Title: "}</strong>
          {/*eslint-disable-next-line*/}
          <a href={props?.data?.url?.toString()} target="_blank">
            {props?.data?.title?.toString()}
          </a>
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          <strong>{"Source: "}</strong>
          {capitalizeFirstLetter(props?.data?.source?.toString())}
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          <strong>{"Total reviews found: "}</strong>
          {formatNReviews(props?.reviews?.reviews.length)}
        </Typography>
        <br />
        <Typography
          display="inline"
          variant="body1"
          style={{
            color: getGrade(
              percentile,
              props.reviews.reviews.length
            )[1],
          }}
        >
          <strong>
            {getGrade(percentile, props.reviews.reviews.length)[0]}
          </strong>
        </Typography>
      </CardContent>
    </Card>
  );
};

const ReviewPanel = (props) => {
  const classes = useStyles();

  const columns = [
    {
      name: "created_date",
      label: "Created Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          if (
            value !== undefined &&
            !(value.length === 1 && value[0] === null)
          ) {
            return <TimeConverter time={value}></TimeConverter>;
          } else {
            return <p />;
          }
        },
      },
    },
    {
      name: "review_content",
      label: "Review Content",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: false,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 50,
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {props.data.reviews.length === 0 ? (
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            This app is rated{" "}
            {props.metadata.content_rating || "(app rating not avaible)"} on the{" "}
            {capitalizeFirstLetter(props.metadata.source) ||
              "(app store not found)"}{" "}
            app store and may not be appropriate for your child. Please use caution and the {<ManyResourceHyperlink/>} available on the internet for navigating these challenges.
          </Typography>
        ) : (
          <>
            <Typography
              // className={classes.title}
              variant="h5"
              gutterBottom
            >
              Selected Reviews
            </Typography>
            <Typography
              // className={classes.title}
              variant="body1"
              gutterBottom
            >
              These reviews were selected by an automated process that looks for
              indications of danger. Please report any incorrectly classified
              reviews to us at {<ErrorReportingHyperlink/>}.
            </Typography>
            <Typography variant="body1" gutterBottom>
              This app is rated{" "}
              {props.metadata.content_rating || "(app rating not avaible)"} on
              the{" "}
              {capitalizeFirstLetter(props.metadata.source) ||
                "(app store not found)"}{" "}
              app store and may not be appropriate for your child. Please use caution and the {<ManyResourceHyperlink/>} available on the internet for navigating these challenges.
            </Typography>
            <br />
            <MUIDataTable
              data={props.data.reviews}
              columns={columns}
              options={options}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

const QuickLinksPanel = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <div align="center">
              <IconButton
                onClick={() => {
                  handleClick();
                  copyToClipboard(window.location.href);
                }}
              >
                <AssignmentIcon />
              </IconButton>
              <Typography>Copy the URL of this page</Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Link Copied to Clipboard!"
      />
    </Card>
  );
};

const DataPanel = (props) => {
  const [appMetadata, setAppMetadata] = useState({});
  const [appReviews, setAppReviews] = useState({ reviews: [] });
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (!props?.match?.params?.AppName) {
      props.history.push({
        pathname: "/",
      });
    }
    var appName = props.match.params.AppName;
    var appSource = props.match.params.AppSource;
    axios
      .get(
        api.convertTitle + encodeURIComponent(appName) + "/" + appSource
      )
      .then((res) => {
        axios
          .get(api.appAllData + res.data.name + "/" + appSource)
          .then((res) => {
            const arr = ["app_info", "app_reviews"];
            const hasAllKeys = arr.every((item) =>
              res.data.hasOwnProperty(item)
            );
            if (hasAllKeys) {
              setAppReviews(res.data.app_reviews);
              delete res.data.app_info.id;
              delete res.data.app_info.miscellaneous;
              setAppMetadata(res.data.app_info);
            } else {
              setErrorState(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }, [props.history, props.match.params.AppName, props.match.params.AppSource]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {errorState ? (
        <Typography variant="h3" gutterBottom component="div">
          Loading...
        </Typography>
      ) : (
        <div>
          <div style={{ marginTop: "20px" }} />
          {/* <div style={{ display: "flex" }}>
            <div style={{ width: "50%", marginRight: "40px" }}>
              <InfoPanel data={appMetadata} />
            </div>
            <div style={{ width: "50%", marginLeft: "40px" }}>
              <QuickLinksPanel appMetadata={appMetadata} /> <br />
            </div>
          </div> */}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InfoPanel data={appMetadata} reviews={appReviews} />
            </Grid>
            <Grid item xs={6}>
              <QuickLinksPanel appMetadata={appMetadata} />
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }} />
          <ReviewPanel metadata={appMetadata} data={appReviews} />
        </div>
      )}
      <br />
    </div>
  );
};

export default DataPanel;
