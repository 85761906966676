import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Accordion from '@mui/material/Accordion';
import Box from "@material-ui/core/Box";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AppDangerPaperHyperlink, BrianBioHyperlink, DontationHyperlink, EdBioHyperlink, ErrorReportingHyperlink, HaniBioHyperlink, NcmecReportingHyperlink, NewAppAnalysisHyperlink, ParentResourcesHyperlink, Soups2022Hyperlink } from "components/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  accordion_sx: {
    fontWeight: 'bold',
    m: 1,
  }
}));

const FAQ = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box sx={{ width: "100%", justifyContent: "center" }}>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid container style={{ justifyContent: "center" }}>
            <Typography gutterBottom variant="h4" component="div">
              Frequently Asked Questions
            </Typography>
          </Grid>
        </Box>
        <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>How were these reviews identified?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We identified reviews using a basic machine learning technique that analyzes reviews alongside manual human review. More details can be found in our paper: {<AppDangerPaperHyperlink/>}.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>What do you want Apple and Google to do about these reviews?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We believe these reports of abuse should be displayed prominently to parents and users on Apple and Google’s app stores. In addition to choices such as “Sort by Most Critical” we believe Apple and Google should have an option to “Sort by Reports of Abuse”. 
            Any report of child sexual abuse to Apple and Google should prompt an investigation into the moderation practices of the app developer, as well as an investigation of the possibility that there is population of dangerous users that the developers may have allowed to thrive on their app. The result of the investigation should be posted publicly in the app stores. 
            If you read some of these reviews, you might agree with us that some apps should be removed from their stores immediately. Cars undergo safety recalls; planes are ground for safety problems; bridges and roads are closed for repair when they are unsafe. Google and Apple should be suspending the download of apps when there reports of child sexual abuse until the apps can demonstrate action has been taken.  
            The age ratings that Apple and Google advertise to consumers should reflect these reports of child sexual abuse — as well as accounts in courts and in the news — rather than the intentions of the developer. Age ratings should reflect the content on the app that passes the developer’s moderation process (if any). Currently, Apple and Goggle’s age ratings appear to reflect the intentions of a developer and not what users actually experience.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>How do I report an error on this site?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           If you spot a review that you believe is innacurate, please report the review to us with {<ErrorReportingHyperlink />}.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>How do I suggest an app to add to the site?
            </Box>
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography>
            To suggest an app for us to analyze, please use {<NewAppAnalysisHyperlink />}.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>What should I do if I encounter abuse on an app?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you have encountered or observed abuse on an app please report it as a CyberTip to the {<NcmecReportingHyperlink />}. Consider involving law enforcement if you need help.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>Where can I learn the details of your work and methods?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For more information about this project, please read our paper.  
            {" "}<br></br>{" "}<br></br>
                <b>{<AppDangerPaperHyperlink/>}</b>. {" "}<br></br>
                {<BrianBioHyperlink/>}, Jagath Jai Kumar, {<HaniBioHyperlink/>},
                Eloghosa Ikponmwoba, and {<EdBioHyperlink/>}.{" "}<br></br>
                In Proceedings of {<Soups2022Hyperlink/>}. May 2022.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>How do you know these reviews are not fake?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We know only that these are reviews that Apple and Google have deemed sufficiently real to appear on their own websites where users can download apps. Some may be fake; some may be accurate reflections of users’ experiences on these apps.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel9a-content"
          id="panel9a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>How should parents make use of these reviews? 
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can makes use of the information in the reviews presented on this site alongside other resources. The following is a list of websites that can help you and your children navigate these challenges:
            <ParentResourcesHyperlink/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded='true'>
        <AccordionSummary
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            <Box sx className={classes.accordion_sx}>How can visitors to AppDanger help?
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you would like to donate to this projects and other efforts by the UMass Rescue Lab to thwart internet-based child sexual abuse, please visit our {<DontationHyperlink />}. We are a non-profit organization and your help can allow us to expand this effort and other projects we have that help rescue children from internet-based sexual abuse. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      </Box>
    </div>
  );
};

export default FAQ;
