import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <footer>
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Typography variant="body1">
          UMass Rescue Lab, UC Berkeley, Rigr.AI, and Oak Foundation
        </Typography> */}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
