import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { api } from "api";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ErrorReportingHyperlink, NewAppAnalysisHyperlink } from "components/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));
function capitalizeFirstLetter(string) {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch {
    return "";
  }
}
const Ranking = (props) => {
  const classes = useStyles();
  const [rankings, setRankings] = useState([]);

  useEffect(() => {
    axios.get(api.rankings).then((res) => {
      setRankings(res.data.rankings);
    });
  }, []);

  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          let row_info = rankings[tableMeta.rowIndex];
          return (
            <Link
              href={"/datapanel/" + row_info.title + "/" + row_info.source}
              color="primary"
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return capitalizeFirstLetter(value);
        },
      },
    },
  ];

  const options = {
    selectableRowsHideCheckboxes: true,
    rowsPerPageOptions: [10, 50, 100],
    sort: false,
    rowsPerPage: 50,
    search: false,
  };

  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <CardContent>
          <Box sx={{ width: "100%", justifyContent: "center" }}>
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography variant="h4" gutterBottom component="div">
                Is this app safe for my child?
              </Typography>
            </Box>
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography variant="body1" gutterBottom component="div">
                Determining if an app is safe for a child is a problem for
                parents around the world. One source of information is the
                reviews posted to Apple’s and Google’s stores written by users.
                To increase the visibility of reports of child sexual abuse, we
                collected app reviews that raise concern of child exploitation.
                You can see those reviews here.
              </Typography>
            </Box>
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography variant="body1" gutterBottom component="div">
                In the listing of apps below, we have sorted apps by the number
                of reviews from the app stores where users have reported
                dangerous situations for children.
                To discover such reviews, we use a machine learning algorithm.
                If you would like to report an inaccuracy, please use {<ErrorReportingHyperlink/>}.
                To suggest an app for us to analyze, please use {<NewAppAnalysisHyperlink/>}.
              </Typography>
            </Box>
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography variant="body1" gutterBottom component="div">
               <Box fontWeight='Bold' display='inline'>
                To search for a specific app, use the 
                <span>&nbsp;</span>
                <Link href={"/search/"} color="primary">
                  Search page
                </Link>.
                </Box>
              </Typography>
            </Box>
          </Box>
          <MUIDataTable data={rankings} columns={columns} options={options} title="Selected apps with at least some reviews indicating dangerousness"/>
        </CardContent>
      </Card>
    </div>
  );
};

export default Ranking;
